export const clientes = [
    "https://media.grupoinveste.pt/cl/ABSOLUTPHARMA.jpg",
    "https://media.grupoinveste.pt/cl/abyss.jpg",
    "https://media.grupoinveste.pt/cl/acunetix.png",
    "https://media.grupoinveste.pt/cl/arbor.png",
    "https://media.grupoinveste.pt/cl/armis.png",
    "https://media.grupoinveste.pt/cl/artcuratorgrid.jpg",
    "https://media.grupoinveste.pt/cl/barral.png",
    "https://media.grupoinveste.pt/cl/boatcenter.jfif",
    "https://media.grupoinveste.pt/cl/bombeiros.png",
    "https://media.grupoinveste.pt/cl/bomportohotels.png",
    "https://media.grupoinveste.pt/cl/Brainclick_Logo.png",
    "https://media.grupoinveste.pt/cl/casais.png",
    "https://media.grupoinveste.pt/cl/Check_Point-Logo.wine.png",
    "https://media.grupoinveste.pt/cl/cisco.png",
    "https://media.grupoinveste.pt/cl/DECORMAR.png",
    "https://media.grupoinveste.pt/cl/DGLOBE.png",
    "https://media.grupoinveste.pt/cl/equipadealuguer.png",
    //"https://media.grupoinveste.pt/cl/Esoterica_Logo.PNG",
    "https://media.grupoinveste.pt/cl/F5_Networks-Logo.wine.png",
    "https://media.grupoinveste.pt/cl/forcepoint.png",
   // "https://media.grupoinveste.pt/cl/Greenqoppa_Logo.png",
    "https://media.grupoinveste.pt/cl/grupo.png",
    "https://media.grupoinveste.pt/cl/Grupo_WBD_Logo.png",
    "https://media.grupoinveste.pt/cl/hotelcascais.jpg",
    //"https://media.grupoinveste.pt/cl/hoteldostemplarios.png",
    "https://media.grupoinveste.pt/cl/iberfar.png",
    "https://media.grupoinveste.pt/cl/IBM.png",
    "https://media.grupoinveste.pt/cl/Indimco-logo.png",
    "https://media.grupoinveste.pt/cl/ingremate.png",
    "https://media.grupoinveste.pt/cl/institutodemarketingresearch.png",
    "https://media.grupoinveste.pt/cl/Kali_Linux_2.0_wordmark.svg.png",
    "https://media.grupoinveste.pt/cl/KTL.png",
    "https://media.grupoinveste.pt/cl/Leoes_de_Porto_Salvo.png",
    "https://media.grupoinveste.pt/cl/lincecapital.jpg",
    "https://media.grupoinveste.pt/cl/Logo_Sobreiras.png",
    "https://media.grupoinveste.pt/cl/lusiadas.png",
    "https://media.grupoinveste.pt/cl/M.jpg",
    "https://media.grupoinveste.pt/cl/MARINA CLUB.png",
    "https://media.grupoinveste.pt/cl/mercado_eletronico_2-04-crop.png",
    "https://media.grupoinveste.pt/cl/nessus.png",
    "https://media.grupoinveste.pt/cl/netscout.png",
    "https://media.grupoinveste.pt/cl/NeuroVida-Logo.png",
    "https://media.grupoinveste.pt/cl/noronhasanches.png",
    "https://media.grupoinveste.pt/cl/open_vas_logo.webp",
    "https://media.grupoinveste.pt/cl/p55ART.png",
    "https://media.grupoinveste.pt/cl/paloalto.png",
    "https://media.grupoinveste.pt/cl/Quintadamimosa-Logo.png",
    "https://media.grupoinveste.pt/cl/rapid 7.png",
    "https://media.grupoinveste.pt/cl/rotovedras_logo_cut.png",
    "https://media.grupoinveste.pt/cl/sinalcabo.png",
    "https://media.grupoinveste.pt/cl/smartconstroi-seo.png",
    "https://media.grupoinveste.pt/cl/SOMAFEL.png",
    "https://media.grupoinveste.pt/cl/Sophos_logo.png",
    "https://media.grupoinveste.pt/cl/symantec.png",
    "https://media.grupoinveste.pt/cl/tecnilab.png",
    "https://media.grupoinveste.pt/cl/Tempoanalógico_Logo.png",
    "https://media.grupoinveste.pt/cl/tenable.png",
    "https://media.grupoinveste.pt/cl/thelumiares.jpg",
    "https://media.grupoinveste.pt/cl/therebello_logo.png",
    "https://media.grupoinveste.pt/cl/THEVINTAGELISBON.png",
    "https://media.grupoinveste.pt/cl/Trend_Micro.png",
    "https://media.grupoinveste.pt/cl/varonis.jpg",
    "https://media.grupoinveste.pt/cl/viphotels.png"
] 