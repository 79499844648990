import './btnScrool.css'
import { HTMLProps } from 'react'

interface propsBtnScrool extends HTMLProps<HTMLAnchorElement> {
    message: string,
    color?: string
}
export default function BtnScrool(props: propsBtnScrool) {

    return (
        <a id="btnScrool" {...props} >
            <svg viewBox="0 0 608 307" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M143.656 125.703C143.656 140.434 155.712 152.421 170.527 152.421H304.306C395.089 152.421 458.71 92.4855 463.213 9.87617C463.504 4.53256 467.716 0.34433 473.09 0.34433H597.137C602.656 0.34433 607.304 4.96584 607.159 10.4539C602.656 191.992 475.85 306.085 304.451 306.085H25.4193C11.3298 306.085 0 294.821 0 280.812V10.165C0 4.67699 4.50286 0.34433 9.87723 0.34433H133.778C139.298 0.34433 143.656 4.82141 143.656 10.165C143.656 48.7257 143.656 87.1419 143.656 125.703Z" fill={props.color || "black"} />
            </svg>
            <svg viewBox="0 0 608 306" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M464.199 180.383C464.199 165.652 452.143 153.665 437.327 153.665H303.549C212.766 153.665 149.145 213.6 144.642 296.209C144.351 301.553 140.139 305.741 134.765 305.741H10.7182C5.19856 305.741 0.550444 301.12 0.695698 295.632C5.34381 114.093 132.005 7.62939e-06 303.549 7.62939e-06H582.581C596.67 7.62939e-06 608 11.2649 608 25.2738V295.92C608 301.408 603.497 305.741 598.123 305.741H474.222C468.702 305.741 464.345 301.264 464.345 295.92C464.345 257.36 464.345 218.944 464.345 180.383H464.199Z" fill={props.color || "black"} />
            </svg>

            <p style={{color: props.color || "black"}}>{props.message}</p>
        </a>
    )
}