export const rh = [{
  "nome": "André Ferreira",
  "link": "https://media.grupoinveste.pt/gp/person/Andre_Ferreira.jpg"
},
{
  "nome": "André Loureiro",
  "link": "https://media.grupoinveste.pt/gp/person/Andre_Loureiro.jpg"
},
{
  "nome": "Fernando Duarte",
  "link": "https://media.grupoinveste.pt/gp/person/Fernando_Duarte.jpg"
},
{
  "nome": "goncalo gil",
  "link": "https://media.grupoinveste.pt/gp/person/goncalo_gil.jpg"
},
{
  "nome": "Hugo Ré",
  "link": "https://media.grupoinveste.pt/gp/person/Hugo_Re.jpg"
},
{
  "nome": "Jorge Delgado",
  "link": "https://media.grupoinveste.pt/gp/person/Jorge_Delgado.jpg"
},
{
  "nome": "Liliana Batista",
  "link": "https://media.grupoinveste.pt/gp/person/Liliana_Batista.jpg"
},
{
  "nome": "Miguel Gonçalves",
  "link": "https://media.grupoinveste.pt/gp/person/Miguel_Goncalves.jpg"
},
{
  "nome": "Pedro Costa",
  "link": "https://media.grupoinveste.pt/gp/person/Pedro_Costa.jpg"
},
{
  "nome": "Ricardo Carvalho",
  "link": "https://media.grupoinveste.pt/gp/person/Ricardo_Carvalho.jpg"
},
]
